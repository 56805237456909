<template>
    <div class="page-container">

        <div class="main-section-container">   
            <h1 class="site-heading">CAVEMAN UPDATES</h1>
            <div class="version-text">
                <b>08/09/2022 Update v2.0.1: -</b>
                <ul>
                    <li>Performance Improvements</li>
                        <ul>
                            <li>Parallax - Cut parallax section so they only render on visible area of the screen</li>
                            <li>Tiles - Cut the amount of tiles rendered off screen for each axis from 2 to 1</li>
                        </ul>
                 </ul>
                <b>04/09/2022 Update v2.0.0: -</b>
                <ul>
                    <li>Tiles</li>
                        <ul>
                            <li>Redrawn all tiles on sprite sheet with tiles 128 x 128-pixel size</li>
                            <li>Added additional tiles types</li>
                            <li>Tiles that damage player</li>
                        </ul>
                    <li>Player</li>
                        <ul>
                            <li>Added player health</li>
                            <li>Added player respawn when health reaches 0</li>
                            <li>Added in air attack with new animation</li>
                            <li>Added hit state with an animation</li>
                            <li>Redrew some frame to remove outlines on SVG elements</li>
                        </ul>
                    <li>Camera</li>
                        <ul>
                             <li>Add a free scrolling camera mode with sway for smoother viewing</li>
                             <li>Added stopping of scrolling on the Y axis when on a solid tile until the player either; lands on another solid tile, the player goes below the previous solid tile or move off the top of the screen</li>
                           
                        </ul>
                    <li>Added parallax background with the option to adjust movement speed relevant to the scrolling X position</li>
                    <li>Add collectables that are removed when touched by the player</li>
                    <li>Add overlayed game UI with health bar</li>
                </ul>
                <b>31/07/2022 Initial release v1.0.0 -</b><br>
                <ul>
                    <li>Tiles</li>
                        <ul>
                            <li>Tiles are cut out from single sprite sheet, and rendered based on tile number</li>
                            <li>Vertical and horizontal scrolling</li>
                            <li>Player collision detection from all 4 directions on set solid tiles</li>
                        </ul>
                    <li>Player</li>
                        <ul>
                            <li>Inputs</li>
                                <ul>
                                    <li>Moves left and right based on arrow presses</li>
                                    <li>Jumps on up arrow press</li>
                                    <li>Attacks on D button press</li>
                                </ul>
                                
                            <li>Animation: player animation states for idle, moving, attacking, in air going up and down</li>
                            
                        </ul>
                    <li>Background renders one time on loading as separate canvas layer for improved performance.</li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'cavemanVersionsPage',
}
</script>

<style lang="scss" scoped >
.version-text {
    padding: 10px;
    min-height: 400px;
    max-width: 1000px;
    width: 100%;
    background: #fff;
    border-radius: 0px 0px 25px 25px;
}
</style>